import { updatePassword } from "firebase/auth";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { passwordSchema } from "./schemas";
import init from "./firebase";
const ChangePassword = () => {
  let navigate = useNavigate();
  const [pShow, setPShow] = useState(false);
  const [cShow, setCShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: passwordSchema,
    onSubmit: async (values) => {
      try {
        const res = await updatePassword(
          init.auth.currentUser,
          values.confirmPassword
        );
        toast.success(
          "Password changed successfully... Please login to continue"
        );
        navigate("/login-admin");
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-white">
                <p>
                  <b>Change Password</b>
                </p>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="input-group mb-2">
                    <input
                      type={pShow ? "text" : "password"}
                      className={`form-control ${
                        formik.touched.password && formik.errors.password
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Create New Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="password"
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setPShow(!pShow)}
                    >
                      {pShow ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger mb-2">
                      {formik.errors.password}
                    </div>
                  ) : null}
                  <div className="input-group mb-2">
                    <input
                      type={cShow ? "text" : "password"}
                      className={`form-control ${
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Confirm Password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="confirmPassword"
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setCShow(!cShow)}
                    >
                      {cShow ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-danger mb-2">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                  <div className="form-group">
                    <button className="btn btn-primary" type="submit">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
