import "./App.css";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import init from "./firebase";
import { Link } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi";
import "react-toastify/dist/ReactToastify.css";

const ComplainForm = () => {
  const fileInputRef = useRef(null);
  const [data, setData] = useState({
    userId: "",
    description: "",
    mobno: "",
    name: "",
    delarMobno: "",
  });
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  const [loading, setLoading] = useState(false);
  const imgHandler = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const storageRef = ref(init.storage, `${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setLoading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("downloadURL", downloadURL);
          setImgUrl(downloadURL);
          setLoading(false);
        });
      }
    );
  };
  const SubmitHandler = async () => {
    const { userId, description, mobno, name, delarMobno } = data;
    console.log("data", data, "image", imgUrl);

    if (userId !== "" && description !== " " && mobno !== "" && name !== "") {
      try {
        const docRef = await addDoc(collection(init.db, "complains"), {
          userId,
          description,
          mobno,
          name,
          delarMobno,
          // image: imgUrl,
          createdAt: serverTimestamp(),
        });
        // console.log("imgUrl", docRef);
        toast.success("Complain Added successfully.");
        setData({
          userId: "",
          description: "",
          mobno: "",
          name: "",
          delarMobno: "",
          image: "",
        });
        fileInputRef.current.value = null;
        setImgUrl("");
      } catch (err) {
        console.log("Error" + err);
      }
    } else {
      toast.error("Please fill all the field");
    }
  };
  return (
    <div className="form-bg ">
      <div className="container">
        <div className="row">
          <div className="form-style">
            <div className="col-md-6">
              <center>
                <h4
                  style={{
                    fontWeight: "bold",
                    fontSize: "35px",
                    fontFamily: "monospace",
                    color: "white",
                  }}
                >
                  Complaint
                </h4>
                <hr className="text-white" style={{ width: "300px" }} />
              </center>
              <div className="row">
                <form>
                  <div className="form-group mb-3">
                    <label htmlFor="exampleInputEmail1" className="text-white">
                      User Id <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter User Id"
                      name="userId"
                      value={data.userId}
                      onChange={formHandler}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="text-white"
                    >
                      Your Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-input"
                      id="exampleInputPassword1"
                      placeholder="Enter Your Name"
                      name="name"
                      value={data.name}
                      onChange={formHandler}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="text-white"
                    >
                      Your WhatsApp Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control custom-input"
                      id="exampleInputPassword1"
                      name="mobno"
                      value={data.mobno}
                      onChange={formHandler}
                      placeholder="Enter Mobile Number"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="text-white"
                    >
                      Dealer WhatsApp Number (optional)
                    </label>
                    <input
                      type="tel"
                      className="form-control custom-input"
                      id="exampleInputPassword1"
                      name="delarMobno"
                      value={data.delarMobno}
                      onChange={formHandler}
                      placeholder="Enter Dealer Number"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="text-white"
                    >
                      Issue Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control custom-input"
                      id="exampleFormControlTextarea1"
                      placeholder="Enter Issue Description"
                      rows="3"
                      name="description"
                      value={data.description}
                      onChange={formHandler}
                    ></textarea>
                  </div>
                  {/* <div className="form-group mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="text-white"
                    >
                      Issue Image (optional)
                    </label>
                    <input
                      type="file"
                      className="form-control custom-input"
                      id="exampleInputPassword1"
                      placeholder="Enter Image"
                      name="image"
                      ref={fileInputRef}
                      onChange={imgHandler}
                    />
                  </div> */}
                </form>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Link to="/">
                  <button
                    type="button"
                    className="btn"
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      width: "120px",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    <HiArrowLeft size={18} /> Back
                  </button>
                </Link>
                <button
                  type="submit"
                  onClick={SubmitHandler}
                  disabled={loading}
                  className="btn"
                  style={{
                    backgroundColor: loading ? "grey" : "green",
                    color: "white",
                    width: "120px",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplainForm;
