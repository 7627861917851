import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import init from "../firebase";
const auth = init.auth;

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const currentUserId = user?.uid;

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }

  const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    const getUserData = async () => {
      if (!currentUserId) return;

      try {
        const userRef = doc(init.db, "admins", currentUserId);
        let res = await getDoc(userRef);
        setCurrentUser(res.exists() ? res.data() : {});
      } catch (err) {
        console.log("user detail fetching error", err);
      }
    };

    getUserData();
  }, [currentUserId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <userAuthContext.Provider
        value={{
          currentUser,
          user,
          logIn,
          signUp,
          logOut,
        }}
      >
        {children}
      </userAuthContext.Provider>
    </>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
