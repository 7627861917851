import logo from "./logo.png";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import ComplainForm from "./ComplainForm";
import { ToastContainer } from "react-toastify";
import ComplainTable from "./ComplainTable";
import Login from "./Login";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";

function App() {
  return (
    <>
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="complain-form" element={<ComplainForm />} />
          <Route
            path="all-complains"
            element={
              <ProtectedRoute>
                <ComplainTable />
              </ProtectedRoute>
            }
          />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="logout" element={<Logout />} />
          <Route path="login-admin" element={<Login />} />
        </Routes>
      </UserAuthContextProvider>
      <ToastContainer />
    </>
  );
}

export default App;
