import React, { useEffect, useState } from "react";
import init from "./firebase";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { MdEmail, MdPassword } from "react-icons/md";
import logo from "./logo.png";
const Login = () => {
  let navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const loginHandler = async (e) => {
    e.preventDefault();
    const { email, password } = data;

    if (email !== "" && password !== "") {
      const auth = init.auth;
      try {
        const user = await signInWithEmailAndPassword(auth, email, password);
        console.log("user", user);
        // const docRef = doc(init.db, "admins", user.user.uid);
        // const docSnap = await getDoc(docRef);
        // if (docSnap.exists()) {
        //   const role = docSnap.data().role;
        //   if (role == "staff" || "admin") {
        //     // toast.success("successfully logged in");
        //     navigate("/dashboard/dash_board");
        //   } else {
        //     toast.error("You're not autherise to access.");
        //     signOut(init.auth);
        //     navigate("/");
        //   }
        //   console.log("Document data:");
        // } else {
        //   toast.error("You're not autherise to access.");
        //   signOut(init.auth);
        //   navigate("/");
        // }
        navigate("/all-complains");
        setData({
          email: "",
          password: "",
        });
      } catch (error) {
        toast.error(error.message);
        setData({
          email: "",
          password: "",
        });
      }
    } else {
      toast.error("All field required");
    }
  };
  const loginFormHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  return (
    <center>
      <div className="container my-4">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-3">
              <div className="text-center pt-3 pb-3">
                <span className="db">
                  <img
                    src={logo}
                    style={{
                      height: "150px",
                      width: "260px",
                      objectFit: "contain",
                    }}
                  />
                </span>
              </div>
              <form className="form-horizontal mt-3" onSubmit={loginHandler}>
                <div className="row pb-4">
                  <div className="col-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text  text-white h-100"
                          id="basic-addon1"
                          style={{ backgroundColor: "#185485" }}
                        >
                          <MdEmail size={24} />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg custom-input"
                        placeholder="Email Address"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="email"
                        onChange={loginFormHandler}
                        value={data.email}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text  text-white h-100"
                          id="basic-addon2"
                          style={{ backgroundColor: "#185485" }}
                        >
                          <MdPassword size={24} />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control form-control-lg custom-input"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        name="password"
                        onChange={loginFormHandler}
                        value={data.password}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="pt-3 d-grid">
                        <button
                          className="btn btn-block btn-lg "
                          type="submit"
                          style={{
                            backgroundColor: "#65e7dd",
                            fontWeight: "bold",
                          }}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default Login;
