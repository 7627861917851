import logo from "./logo.png";
import appicon from "./appicon.png";
import "./App.css";
import React from "react";
import { Link } from "react-router-dom";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <Link to="https://bpexch.com/Users/Login">
          <button
            className="login-button"
            role="button"
            // style={{ marginRight: "10px" }}
          >
            Login
          </button>
        </Link>
        <Link to="/complain-form">
          <button className="login-button" role="button">
            Complaint
          </button>
        </Link>

        {/* <div
          className=""
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h6 className="my-4">
            {" "}
            Powered By{"  "}
            <ImArrowDown size={14} />
            <ImArrowUp size={14} />{" "}
            <span
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                fontFamily: "monospace",
              }}
            >
              Betfair
            </span>
          </h6>
          <img src={appicon} className="louncher-logo" alt="logo" />
        </div> */}
      </header>
    </div>
  );
};

export default Home;
